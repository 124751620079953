<template>
  <div class="loading">
    <img src="../assets/manialab.png" alt="logo">
    <h2>Redirecting you now...</h2>
  </div>
</template>

<style>
* {
  font-family: Arial, Helvetica, sans-serif;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading img {
  margin: 150px 0 50px 0;
  width: 400px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.1;
  }

  100% {
    scale: 1;
  }
}
</style>

<script>
export default {
  mounted() {
    this.axios.post('https://il.manialab.sa/api/redirect',
      {
        'link_id': this.$route.query.id.substring(3),
        'referal_id': this.$route.query.ref
      }
    ).then(response => {
      if (!response.data.success) {
        // return swal('Error!', response.data.msg, 'error');
      } else {
        window.location.href = response.data.data.redirection_url
      }
    });
  }
}
</script>
